import { useLocation, Routes, Route } from 'react-router-dom';
import React, { lazy, Suspense, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spin } from 'library';
import { GnChannelService } from 'services';
import { fetchConversation } from 'store/call';
import { clearIncident } from 'store/incident';
import { signIn } from 'store/me';
import Root from './Root';

const RoutePlayground = lazy(() => import('./Playground'));
const RoutesRN = lazy(() => import('./RoutesRN'));
const RoutesIC = lazy(() => import('./RoutesIC'));

const LaxRoutes = () => {
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    const call = useSelector(state => state.call);
    const { pathname } = useLocation();
    const genesysUserId = me.$genesys?.id;

    useEffect(() => {
        dispatch(signIn());
    }, [dispatch]);

    const onCall = useCallback((event) => {
        if (pathname === '/') {
            if (event.id && !call.id) {
                dispatch(clearIncident(true));
                dispatch(fetchConversation(event.id));
            }
        }
    }, [pathname, dispatch, call.id]);

    useEffect(() => {
        if (genesysUserId) {
            GnChannelService.subscribeToCalls(genesysUserId, onCall);
        }
    }, [genesysUserId, onCall]);

    return (
        <Suspense fallback={<Spin fullscreen tip="Logging..." />}>
            <Routes>
                <Route path="/playground" element={<RoutePlayground />} />
                <Route element={<Root />}>
                    {!me.$role && <Route path="/*" element={<Spin fullscreen tip="Authenticating..." />} />}    
                    {me.$role === 'RN' && <Route path="/*" element={<RoutesRN />} />}
                    {me.$role === 'IC' && <Route path="/*" element={<RoutesIC />} />}
                </Route>
            </Routes>
        </Suspense>
    );
};

export default LaxRoutes;
