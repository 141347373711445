import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Tree, Input, Button, Skeleton, Form } from 'library';
import { EsLocationsService } from 'services';
import { useService } from 'hooks';
import LocationTitle from './LocationTitle';

const LookupTopLocationPopup = ({ root, setOpen }) => {
    const [filter, setFilter] = useState('');
    const [selected, setSelected] = useState();
    const [loadChildren, treeData, loading] = useService(EsLocationsService.children);
    const form = Form.useFormInstance();

    useEffect(() => {
        if (root.id) {
            loadChildren(root);
        }
    }, [root, loadChildren]);

    const onConfirm = () => {
        form.setFieldsValue({ locationId: selected.id });
        setOpen(false);
    };

    const onSelect = ([key], { node }) => {
        setSelected(key ? node : null);
    };

    const filterTreeNode = (node) => {
        if (!filter || !node.reportableLocation) {
            return false;
        }
        return node?.title.toLowerCase().includes(filter?.toLowerCase());
    };

    return (
        <div className="lookup-top-location-popover">
            <Input.Search
                placeholder="Search"
                onChange={e => setFilter(e.target.value)}
                value={filter}
            /> 
            <div className="content">
                <Skeleton
                    active
                    paragraph={{ rows: 10 }}
                    loading={loading}
                >
                    <Tree
                        treeData={treeData}
                        defaultExpandAll
                        filterTreeNode={filterTreeNode}
                        onSelect={onSelect}
                        virtual
                        height={550}
                        blockNode
                        titleRender={LocationTitle}
                    />
                </Skeleton>
            </div>

            <div className="footer">
                <div />
                <Button
                    type="link"
                    onClick={() => setOpen(false)}
                >
                    Cancel
                </Button>
                <Button
                    type="primary"
                    onClick={onConfirm}
                    disabled={!selected}
                >
                    Select
                </Button>
            </div>
        </div>

    );
};

LookupTopLocationPopup.propTypes = {
    root: PropTypes.object,
    setOpen: PropTypes.func.isRequired
};

export default LookupTopLocationPopup;
