import { GENESYS } from 'config';
import dayjs from 'dayjs';
import { GenesysApp } from 'services/genesysApp';
import { usersApi, apiClient, tokensApi } from './utils';

const { clientId, environment, appName } = GENESYS;
const callbackPath = '/';
const callbackUri = `https://${window.location.host}${callbackPath}`;

apiClient.setPersistSettings(true, appName);
apiClient.setEnvironment(environment);

class GnAuthService {
    me;

    static #setupRefreshToken({ tokenExpiryTime }) {
        const expDate = dayjs(tokenExpiryTime);
        const expiresInSeconds = expDate.diff(dayjs(), 'second') - 2;
        setTimeout(() => {
            GnAuthService.signIn();
        }, expiresInSeconds * 1000);
    }

    static signIn() {
        const state = window.location.pathname;
        return apiClient.loginImplicitGrant(clientId, callbackUri, { state })
            .then((data) => {
                const forbiddenPaths = [window.location.pathname, callbackPath];

                if (data.state && !forbiddenPaths.includes(data.state)) {
                    document.location.replace(data.state);
                }

                return GnAuthService.#setupRefreshToken(data);
            })
            .then(() => {
                GenesysApp.alert('Connected successfully! 😊');
            });
    }

    static getAccessToken() {
        return apiClient?.authData.accessToken;
    }

    static me() {
        return usersApi.getUsersMe({ expand: ['presence'] })
            .then((me) => {
                this.me = me;
                return me;
            });
    }

    static deactivateToken() {
        apiClient.authData = { ...apiClient.authData, accessToken: 'unactive' };
    }

    static isSignedIn() {
        return !!GnAuthService.getAccessToken();
    }

    static signOut() {
        return tokensApi.deleteToken(this.me?.id);
    }
}

export { GnAuthService };
