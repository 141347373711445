import './AutoComplete.scss';
import React, { useState, useRef, useMemo } from 'react';
import { Input, AutoComplete as AutoCompleteBase } from 'antd';
import PropTypes from 'prop-types';
import { debounce } from 'utils';
import { useService } from 'hooks';
import { SearchOutlined, LoadingOutlined } from '@ant-design/icons';

const AutoComplete = ({
    onChange,
    fetchOptions,
    placeholder = '',
    value = {},
    disabled = false,
    minLength = 3,
    ...rest
}) => {
    const [query, setQuery] = useState('');
    const fetchRef = useRef(0);
    const [loadData, data, loading] = useService(fetchOptions, { options: [] });

    const debounceSearch = useMemo(() => {
        const loadOptions = (val) => {
            setQuery(val);
            if (val.length < minLength) {
                return;
            }
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            loadData({ query: val })
                .then(res => (fetchId === fetchRef.current ? res : null));
        };
        return debounce(loadOptions, 800);
    }, [loadData, minLength]);

    const hasMinLength = query.length >= minLength;
    const noDataFound = hasMinLength && (!data.options || data.options?.length === 0);
    let notFoundContent = `Please enter ${minLength} or more characters`;
    if (loading) {
        notFoundContent = 'Loading...';
    } else if (noDataFound) {
        notFoundContent = 'Not Found';
    } 

    const dropdownRender = menu => (data.total ? (
        <>
            <div>{menu}</div>
            <div className="auto-complete-footer">
                {Object.isDefined(data.total) && <>Total: <b>{data.total}</b></>}
            </div>
        </>
    ) : menu);

    const onBeforeChange = (val, item) => {
        onChange(val, item?.item);    
};

    return (
        <AutoCompleteBase
            filterOption={false}
            onSearch={debounceSearch}
            popupMatchSelectWidth={false}
            loading={loading}
            options={data.options}
            value={value}
            onChange={onBeforeChange}
            listHeight={350}
            disabled={disabled}
            popupClassName="auto-complete-dropdown"
            notFoundContent={notFoundContent}
            dropdownRender={dropdownRender}
        >
            <Input
                allowClear
                suffix={loading ? <LoadingOutlined /> : <SearchOutlined />}
                placeholder={placeholder}
                {...rest}
            />
        </AutoCompleteBase>
    );
};

AutoComplete.propTypes = {
    fetchOptions: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    value: PropTypes.any,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    minLength: PropTypes.number,
};

export { AutoComplete, AutoCompleteBase };
