import { useEffect, useRef } from 'react';

const useMount = (run = () => null) => {
    const referencedRun = useRef(run);
    useEffect(() => {
        referencedRun.current();
    }, []);
};

export { useMount };
