import {
    InputPhone, Checkbox, InputText, Select,
    InputDateTime, InputTime, InputZip,
    InputBool, TextArea, Confirm,
    InputDateInPast,
    InputDateRange
} from 'library';
import {
    AutoCompleteES,
    InputClaimantSsn,
    InputCallerLocalTime,
    InputAutofillAddress,
    TextAreaInjurySurvey,
} from 'library/CustomES';
import { QUESTION_TYPE } from './types';

export * from './rules';
export * from './types';

export const INJURY_SURVEY_QUESTIONS = [
    'injury_description',
    'injury_nature_of',
    'injury_object_involved',
    'injury_activity_description',
    'injury_pertinent_medical_history'
];

export const YESNO_QUESTION_VALUE_NO = '1';

export const YESNO_QUESTION_VALUE_YES = '2';

const AutofillAddressQuestions = ['claimant_address', 'injury_address'];

const CallerLocalTimeQuesion = 'injury_caller_local_time';

export const getField = ({
    name, type, options, maxLength, fetch, isDirective, multiple = false
}) => {
    if (AutofillAddressQuestions.includes(name)) {
        return <InputAutofillAddress name={name} />;
    }

    if (name === 'claimant_ssn') {
        return <InputClaimantSsn />;
    }

    if (name === CallerLocalTimeQuesion) {
        return <InputCallerLocalTime />;
    }
    if (INJURY_SURVEY_QUESTIONS.includes(name)) {
        return <TextAreaInjurySurvey name={name} />;
    }
    if (name?.endsWith('_zip')) {
        return <InputZip />;
    }

    if (name?.endsWith('_phone') || name?.endsWith('_fax')) {
        return <InputPhone />;
    }

    if (fetch && !options?.length) {
        return <AutoCompleteES fetch={fetch} />;
    }

    if ([QUESTION_TYPE.LOOKUP, QUESTION_TYPE.SELECT]
        .includes(type) && Array.isArray(options)) {
        return <Select options={options} mode={multiple ? 'multiple' : ''} />;
    }

    switch (type) {
        case QUESTION_TYPE.YESNO: {
            return (
                <Select
                    options={[
                        { value: isDirective ? 'No' : YESNO_QUESTION_VALUE_NO, label: 'No' },
                        { value: isDirective ? 'Yes' : YESNO_QUESTION_VALUE_YES, label: 'Yes' }
                    ]}
                />
            );
        }
        case QUESTION_TYPE.CONFIRM:
            return <Confirm />;
        case QUESTION_TYPE.CHECKBOX:
            return <InputBool />;
        case QUESTION_TYPE.CHECKBOXES:
            return <Checkbox.Group options={options} />;
        case QUESTION_TYPE.DATE:
            return <InputDateInPast />;
        case QUESTION_TYPE.DATERANGE:
            return <InputDateRange />;
        case QUESTION_TYPE.TIME:
            return <InputTime />;
        case QUESTION_TYPE.DATETIME:
            return <InputDateTime />;
        case QUESTION_TYPE.TEXTAREA: {
            return <TextArea />;
        }
        case QUESTION_TYPE.TEXT:
        default:
            return <InputText maxLength={maxLength || 1000} />;
    }
};
