import api from '../api';
import Transformer from './transformer';

class EsNotesService {
    static TRIAGE_TYPE = 1;

    static INTAKE_TYPE = 2;

    static ALL_TYPE = 3;

    static create(params) {
        return api.post('/api/v2/notes', params)
            .then(data => data.data)
            .then(Transformer.get);
    }

    static update(id, data) {
        return api.put(`/api/v2/notes/${id}`, data);
    }
    
    static remove(id) {
        return api.delete(`/api/v2/notes/${id}`);
    }

    static list(incidentId) {
        return api.get(`/api/v2/incidents/${incidentId}/notes`, { perPage: 50, page: 1 })
            .then(res => res.data.data)
            .then(Transformer.list);
    }
    
    static library(visibleIn) {
        return api.get('/api/callNotes/library', { visibleIn })
            .then(({ data }) => data.data)
            .then(data => data.map((i, key) => ({ ...i, key, label: i.text })));
    }

    static save(incidentId, data) {
        return api.post(`/api/v2/incidents/${incidentId}/notes`, { data })
            .then(res => res.data.data);
    }
}

export { EsNotesService };
