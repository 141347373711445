import { useEffect, useRef } from 'react';
import { useMount } from './useMount';
/** * Usage
    useMount(() => {});
    useUnMount(() => {}, [val]);
*/

const useUnmount = (effect, dependencies) => {
    const unMounted = useRef(false);

    // React 18 Component will be mounted twice in dev mode, 
    // so setting the reference to false on the second mount
    useMount(() => {
        unMounted.current = false;
    });

    useEffect(() => () => {
        unMounted.current = true;
    }, []);

    useEffect(() => () => {
        if (unMounted.current) {
            effect();
        }
    }, [dependencies, effect]
    );
};

export { useUnmount };
