import './Result.scss';
import PropTypes from 'prop-types';
import { Result as ResultBase } from 'antd';

const Result = ({ className = '', img, title, children }) => (
    <div className={`lax-result ${className}`}>
        <div>
            <img src={img} alt={title} />
            <h1>{title}</h1>
            {children}
        </div>
    </div>
);

Result.propTypes = {
    className: PropTypes.string,
    img: PropTypes.string.isRequired,
    title: PropTypes.string,
    children: PropTypes.any
};

export { Result, ResultBase };
