/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState } from 'react';
import { catchError } from 'utils';

const useService = (service, initialData, then = data => data) => {
    const [data, setData] = useState(initialData);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);

    const fetchMethod = (...args) => {
        setLoading(true);
        return service(...args)
            .then(then)
            .then((res) => {
                setData(res);
                return res;
            })
            .catch((e) => {
                setError(e);
                return catchError(e);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const stableFetch = useCallback(fetchMethod, []);

    return [stableFetch, data, loading, error];
};

export { useService };
