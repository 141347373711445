import { ROLE_ID_INSURANCE, DISTRIBUTION_MODE } from 'config';
import { randomTo } from 'utils';
import api from '../api';

import transformContacts from './transformer.contacts';

class EsLocationsService {
    static get(id) {
        return api.get(`/api/v2/locations/${id}`)
            .then(({ data }) => data);
    }

    static list(args) {
        const filters = { ...args };
        if (filters.assignedPhoneNumber) {
            filters.assignedPhoneNumber = filters.assignedPhoneNumber.replace(/\D/g, '').substr(1);
        }
        const params = {
            page: 1,
            perPage: 100,
            ...filters,
        };

        return api.get('/api/v2/locations', params)
            .then(({ data }) => data)
            .then(({ data }) => data);
    }

    static all(params) {
        return api.get('/api/v2/locations/all', params)
            .then(({ data }) => data);
    }

    static generateTree(children, all) {
        return children.map((item) => {
            const isOff = item.distributionMode === DISTRIBUTION_MODE.OFF;
            const leaf = {
                ...item,
                disabled: !item.reportableLocation || isOff,
                key: `${item.id}-${randomTo(1000, 9999)}`,
            };
            const res = all.filter(child => child.parentId === item.id);
            if (res.length) {
                return {
                    ...leaf,
                    children: this.generateTree(res, all)
                };
            }
            return leaf;
        })
            .sort((a, b) => a.title.localeCompare(b.title));
    }

    static children(parent) {
        const rootId = parent.id;
        return api.get('/api/v2/locations/all', { rootId })
            .then(({ data }) => data)
            .then(all => EsLocationsService.generateTree([{ ...parent, key: parent.id }], all));
    }

    static topLocationByPhone(assignedPhoneNumber) {
        return EsLocationsService.list({
            assignedPhoneNumber,
            // distributionMode: DISTRIBUTION_MODE.LIVE, 
            orderBy: 'lft',
            orderDirection: 'asc',
            page: 1,
            perPage: 1
        })
            .then(data => (data.length ? data[0] : null));
    }

    static externalLocations(filters) {
        const params = {
            ...filters,
            source: 'GB Pyramid',
        };

        return api.get('/api/v2/locations/third-party', params)
            .then(({ data }) => data)
            .then(({ data }) => {
                if (data.error) {
                    const message = data?.message || '';
                    return Promise.reject(Error(`Invalid Response from ${params.source}: ${data.error} ${message}`));
                }
                return data;
            });
    }

    static contacts(locationId, params) {
        return api.get(`/api/v2/locations/${locationId}/contacts`, params)
            .then(({ data }) => data);
    }

    static externalContacts(locationId, params) {
        return api.get(`/api/v2/locations/${locationId}/contacts/third-party`, params)
            .then(transformContacts);
    }

    static insuranceContacts(locationId) {
        const filters = {
            roleType: 1,
            roleId: ROLE_ID_INSURANCE,
        };

        return EsLocationsService.contacts(locationId, filters)
            .then(({ data }) => data
                .map(item => ({
                    ...item,
                    phone: item.workPhone || item.cellPhone || item.homePhone,
                    // eslint-disable-next-line no-nested-ternary
                    phoneType: !!item.workPhone ? 'Work' : (!!item.cellPhone ? 'Mobile' : 'Home')
                }))
                .filter(item => !!item.phone));
    }
}

export { EsLocationsService };
