import { ROLES_RN } from 'config/common';
import { GnAuthService } from '../../genesys';

const ExpiredToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vZW5zcGlyaS5sb2NhbC5vc2kvYXBpL2F1dGgvbG9naW4iLCJpYXQiOjE3MTI4NDkxMDAsImV4cCI6MTcxMjg1MjcwMCwibmJmIjoxNzEyODQ5MTAwLCJqdGkiOiJEVWhveGhaUlA3TU5ZRlVXIiwic3ViIjo5MzIsInBydiI6ImYwZmFmMzA2YTE5NDVhNTBmYzlmYTllMjU0Nzg2ODNiMDc0NDE1MTcifQ.GUCkDVgOjGhpwRZgtpAHuI_z-hxSfHZyrWgluitJTs4';

class EnterpriseAuthService {
    #esToken;

    api;

    constructor(api) {
        this.api = api;
    }

    deactivateToken() {
        this.#esToken = ExpiredToken;
    }

    refreshToken() {
        return this.getAccessToken('/api/auth/refresh');
    }

    async getAccessToken(url, data = {}) {
        const res = await this.api.post(url, data);
        return res?.data?.token;
    }

    async token(renew) {
        if (!this.#esToken || renew) {
            const gcToken = GnAuthService.getAccessToken();
            if (gcToken) {
                this.#esToken = await this.getAccessToken('/api/auth/login', { gcToken });
            }
        }
        if (!this.#esToken) {
            await GnAuthService.signIn();
            const gcToken = GnAuthService.getAccessToken();
            if (gcToken) {
                this.#esToken = await this.getAccessToken('/api/auth/login', { gcToken });
            }
        }

        return this.#esToken;
    }

    async me() {
        const token = await this.token();
        if (token) {
            return this.api.get('/api/auth/me')
                .then(({ data }) => ({
                    ...data,
                    email: data.username,
                    name: `${data.firstName} ${data.lastName}`,
                    $isRN: ROLES_RN.includes(data.role?.id),
                    $role: ROLES_RN.includes(data.role?.id) ? 'RN' : 'IC'
                }));
        }
        return Promise.reject(new Error('Missing or invalid credentials for Lintelio Enterprise'));
    }
}

export default EnterpriseAuthService; 
