import { ROLES_RN } from 'config';

const get = item => ({
    ...item,
    ...item.createdBy,
    $isRN: ROLES_RN.includes(item.createdBy?.roleId),
    email: item.createdBy?.email?.toLowerCase(),
});

const list = data => data.map(get);

export default {
    list,
    get
};
