import { enumToOptions } from 'utils';

export const VERSION = process.env.REACT_APP_VERSION;
export const ENV = process.env.REACT_APP_ENV;
export const ENTERPRISE_URL = process.env.REACT_APP_ENTERPRISE_URL;

export const ROLE_ID_INSURANCE = 28;

export const USER_ID_PYPESTREAM = 559;
export const LOCATION_ID_PYPESTREAM_DEFAULT = 147413;

export const INTERPRETER_TYPES = {
    internal: 1,
    external: 2
};

export const LANGUAGES = {
    1: 'English',
    2: 'Spanish',
    3: 'French',
    4: 'Other'
};

export const LanguageOptions = enumToOptions(LANGUAGES);

export const isProd = ENV === 'production';
export const isLocal = ENV === 'local';

const RnRoles = {
    RN: 6,
    'RN/PA': 34,
};
const IcRoles = {
    'CN IC-': 2,
    'Lead IC-': 4,
    'PA Lead/IC-': 21,
    'PA/IC-': 26,
    'IC Manager': 28,
    'GB IC': 32,
    'GB IC Manager': 33,
};

export const ROLES_RN = Object.values(RnRoles);

export const ROLES_IC = Object.values(IcRoles);
