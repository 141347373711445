import './Header.scss';
import { Watermark } from 'library';
import { useInView } from 'react-intersection-observer';
import { ENV, VERSION, isProd } from 'config';
import { useDispatch, useSelector } from 'react-redux';
import { LogoRN, LogoIC } from 'assets';
import { useNavigate } from 'react-router-dom';
import { clearIncident } from 'store/incident';
import { UserAvatar } from 'components/UserAvatar';
import { signOut } from 'store/me';

const Header = () => {
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    const navigate = useNavigate();
    const watermark = isProd ? '' : ENV;
    const logo = me.$isRN ? LogoRN : LogoIC;
    const { ref, inView } = useInView({ threshold: 0 });

    const goHome = () => {
        dispatch(clearIncident(true));
        navigate('/');
    };

    const onAvatarClick = () => {
        if (!isProd) {
            dispatch(signOut())
                .then(window.location.reload());
        }
    }; 

    return (
        <Watermark
            content={watermark}
            fontSize={14}
            gap={[25, 25]}
            color="#ff0000"
        >
            <div className={`header ${inView ? '' : 'header-out-of-view'}`} ref={ref}>
                <a onClick={goHome}>
                    <img src={logo} alt="Logo" />
                    <span>v. {VERSION}</span>
                </a>
                {me?.name && (
                    <h5>
                        <UserAvatar 
                            isRN={me.$isRN} 
                            onClick={onAvatarClick} 
                            title={isProd ? me?.name : 'Sign Out'}
                        />
                        <div>
                            {me?.name}
                            <h6>{me.role?.title}</h6>
                        </div>
                    </h5>
                )}
            </div>
        </Watermark>
    );
};

export { Header };
