import { ROLES_RN, ROLES_IC } from 'config';
import api from '../api';

class EsUsersService {
    static async list() {
        const roleId = [...ROLES_IC, ...ROLES_RN];
        return api.get('/api/v2/users', { roleId, active: 1 })
            .then(data => data.data)
            .then(data => data.data)
            .then(data => data.map(user => ({
                ...user,
                $role: ROLES_RN.includes(user.role?.id) ? 'RN' : 'IC'
            })).sortByString('firstName'));
    }
}

export { EsUsersService };
