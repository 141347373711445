import EnterpriseClaimant from './Enterprise';
import UKGClaimant from './UKG';
import RandstadClaimant from './Randstad';
import SafeliteClaimant from './Safelite';
import GBClaimant from './GB';
import RubioClaimant from './Rubio';
import PSWClaimant from './PSW';
import EmployeeBridgeClaimant from './EmployeeBridge';
import LithiaMotorsClaimant from './LithiaMotors';
import TriStarClaimant from './TriStar';
import SCTrayClaimant from './SCTray';
import FirstSolarClaimant from './FirstSolar';

const config = {
    'Enspiri API': EnterpriseClaimant,
    Randstad: RandstadClaimant,
    UKG: UKGClaimant,
    'GB API': GBClaimant,
    PeopleHR: { title: 'PeopleHR' },
    'Rubio HR': RubioClaimant,
    'Psw API': PSWClaimant,
    Safelite: SafeliteClaimant,
    EmployeeBridge: EmployeeBridgeClaimant,
    'Lithia Motors': LithiaMotorsClaimant,
    TriStar: TriStarClaimant,
    'SC Tray': SCTrayClaimant,
    'First Solar': FirstSolarClaimant,
};

export default config;

/*
[
    "Last Name (11)",
    "First Name (11)",
    "Middle Name (1)",

    "SSN (9)",
    "Date of Birth (9)",

    "Employee Id (10)",

    "Home Phone (6)",
    "Mobile Phone (3)",
    "Work Phone (2)",
    
    "Occupation (7)",
    "Email (8)",
    "Gender (8)",
    "Marital Status (3)",
    "Hire Date (8)",

    "Address (9)",
    "Address 2 (2)"
    "City (10)",
    "State (10)",
    "Zip (10)",
]
*/
