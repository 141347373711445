export const INCIDENT_TYPE_WC = 1;
export const INCIDENT_TYPE_AUTO = 2;
export const INCIDENT_TYPE_ACCESS_ASSISTANCE = 4;
export const INCIDENT_TYPE_LIABILITY = 5;
export const INCIDENT_TYPE_NON_REVENUE = 6;
export const INCIDENT_TYPE_HIGH_PROFILE_LIABILITY = 9;
export const INCIDENT_TYPE_PROPERTY = 10;
export const INCIDENT_TYPE_WORKPLACE_VIOLENCE = 11;
export const INCIDENT_TYPE_MESSAGE_CENTER = 12;

export const INCIDENT_TYPES = [
    {
        title: 'Workers Compensation',
        abbr: 'WC',
        id: INCIDENT_TYPE_WC,
        genesysProps: ['Injury', 'WC']
    },
    {
        title: 'Auto',
        abbr: 'AU',
        id: INCIDENT_TYPE_AUTO,
        genesysProps: ['Auto']
    },
    {
        title: 'Liability',
        abbr: 'LB',
        id: INCIDENT_TYPE_LIABILITY,
        genesysProps: ['Liability']
    },
    {
        title: 'Property',
        abbr: 'PR',
        id: INCIDENT_TYPE_PROPERTY,
        genesysProps: []
    },
    {
        title: 'Non Revenue',
        abbr: 'NR',
        id: INCIDENT_TYPE_NON_REVENUE,
        genesysProps: []
    },
    {
        title: 'Access Assistance',
        abbr: 'AA',
        id: INCIDENT_TYPE_ACCESS_ASSISTANCE,
        genesysProps: []
    },
    {
        title: 'High Profile Liability',
        abbr: 'HPL',
        id: INCIDENT_TYPE_HIGH_PROFILE_LIABILITY,
        genesysProps: []
    },
    {
        title: 'Workplace Violence',
        abbr: 'WV',
        id: INCIDENT_TYPE_WORKPLACE_VIOLENCE,
        genesysProps: ['WV']
    },
    {
        title: 'Message Center',
        abbr: 'MC',
        id: INCIDENT_TYPE_MESSAGE_CENTER,
        genesysProps: ['MC']
    },
];

export const FLOW_TYPE = {
    INTAKE: 1,
    INTAKE_TRIAGE: 2
};

export const getIncidentType = id => INCIDENT_TYPES.find(item => item.id === id)
    || { title: 'Unknown', abbr: '' };
    
// It is not a real incident state, but a trigger to calculete the new state
export const INCIDENT_STATE_AUTODISPATCH = 6;

export const INCIDENT_STATE = {
    INTAKE: 1,
    NO_TRIAGE: 2,
    TRIAGE: 3,
    TRIAGING: 8,
    VOID: 4,
    DONE: 5,
    // AUTODISPATCH: 6, 
    // ABANDONED: 7
};

export const IncidentStateFilters = [
    { value: `${INCIDENT_STATE.INTAKE}-${INCIDENT_STATE.NO_TRIAGE}`, label: 'Intake', key: 'INTAKE' },
    { value: `${INCIDENT_STATE.TRIAGE}-${INCIDENT_STATE.TRIAGING}`, label: 'Triage', key: 'TRIAGE' },
    { value: INCIDENT_STATE.VOID.toString(), label: 'Voided', key: 'VOID' },
    { value: INCIDENT_STATE.DONE.toString(), label: 'Submitted', key: 'DONE' },
];

export const getIncidentState = value => IncidentStateFilters
    .find(item => item.value.includes(value));
 
export const FinalStates = [INCIDENT_STATE.DONE, INCIDENT_STATE.VOID];
