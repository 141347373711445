/* eslint-disable max-len */
import { formatDateOut, formatDate } from 'utils';

export default {
    source: 'First Solar',
    sourceType: 'contact',
    title: 'First Solar Contacts',
    className: 'w1000 warning',
    description: <>This employer <b>requires</b> you to search and select your claimant from the employer&apos;s database directly!</>,
    columns: [
        {
            fixed: true,
            label: 'Last Name',
            autofill: {
                name: 'claimant_last_name'
            },
            name: 'lastName',
            filter: 'lastName',
            required: true,
            width: 120,
        },
        {
            label: 'First Name',
            autofill: {
                name: 'claimant_first_name'
            },
            name: 'firstName',
            filter: 'firstName',
            required: true,
            width: 120,
        },
        {
            label: 'SSN',
            autofill: {
                name: 'claimant_ssn',
            },
            name: 'ssn',
            filter: 'ssn',
            width: 120
        },
        {
            label: 'Date of Birth',
            name: 'birthDate',
            autofill: {
                name: 'claimant_birth_date',
                transform: formatDateOut
            },
            render: formatDate,
            filter: 'birthDate',
            width: 90
        },
        {
            label: 'Employee Id',
            autofill: {
                name: 'claimant_employee_id'
            },
            name: 'employeeId',
            filter: 'employeeId',
            width: 80
        },
        {
            label: 'Occupation',
            name: 'jobTitle',
            autofill: {
                name: 'employment_job_title_or_occupation',
                editable: true,
            },
            width: 120
        },
        {
            label: 'Department',
            name: 'department',
            autofill: {
                name: 'employment_department_name',
            },
            width: 100
        },
        {
            label: 'Hire Date',
            name: 'hireDate',
            autofill: {
                name: 'employment_hired_date',
                editable: true,
            },
            render: formatDate,
            width: 120
        },
        {
            label: 'Email',
            name: 'email',
            autofill: {
                name: 'claimant_email_address'
            },
            width: 120
        },
        {
            label: 'Home Phone',
            name: 'homePhone',
            autofill: {
                name: 'claimant_home_phone',
                editable: true
            },
            width: 150
        },
        {
            label: 'Work Phone',
            name: 'workPhone',
            autofill: {
                name: 'claimant_work_phone'
            },
            width: 150
        },
        {
            label: 'Gender',
            name: 'gender',
            autofill: {
                name: 'claimant_gender_code',
                transform: value => ({ M: 1, F: 2 }[value] || ''),
            },
            width: 60
        },
        {
            label: 'Marital Status',
            name: 'maritalStatus',
            autofill: {
                name: 'claimant_marital_status_code',
                transform: value => (
                    {
                        S: '2',
                        M: '4',
                        D: '3',
                        // Separated: '5',
                        W: '6',
                        // Unknown: '7',
                        P: '8',
                    }[value] || undefined)
            },
            width: 100
        },
        {
            label: 'Address',
            name: 'address1',
            autofill: {
                name: 'claimant_address'
            },
            width: 120
        },
        {
            label: 'City',
            name: 'city',
            autofill: {
                name: 'claimant_city'
            },
            width: 100
        },
        {
            label: 'State',
            name: 'state',
            // options: USStateOptions,
            autofill: {
                name: 'claimant_state'
            },
            width: 90
        },
        {
            label: 'Zip',
            name: 'zip',
            autofill: {
                name: 'claimant_zip'
            },
            width: 70
        },
    ]
};
