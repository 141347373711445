import React from 'react';
import { ResultBase } from 'library';

const NotFound = () => (
    <ResultBase
        status="403"
        title="403"
        subTitle="Sorry, you are not authorized to access this page."
    />
);

export default NotFound;
