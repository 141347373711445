import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Popover, Form } from 'library';
import { EsLocationsService } from 'services';
import { ImportOutlined, LoadingOutlined } from '@ant-design/icons';

import { DISTRIBUTION_MODE } from 'config';
import { useOutletContext } from 'react-router-dom';
import config from './config';
import { LookupDropdown } from '../Dropdown';

const LookupInjuryLocationField = ({
    loading = false,
    disabled = false,
}) => {
    const form = Form.useFormInstance();
    const { patchAction } = useOutletContext();
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState();
    
    const onOk = async (data) => {
        const { fields } = data;
        setSelected(data);
        form.setFieldsValue({ fields });
        patchAction({ fields });
        return Promise.resolve();
    };

    const service = args => EsLocationsService.list({
        ...args,
        reportableLocation: 1,
        sortBy: 'title',
        sortDirection: 'asc',
        page: 1,
        perPage: 1000,
    });
    
    return (
        <Popover
            content={(
                <LookupDropdown
                    onCancel={() => setOpen(false)}
                    onOk={onOk}
                    setOpen={setOpen}
                    defaultFilters={{
                        distributionMode: DISTRIBUTION_MODE.LIVE,
                    }}
                    service={service}
                    {...config}
                />
            )}
            open={!disabled && open}
            overlayClassName={`lookup-popover ${config.className}`}
            getPopupContainer={() => document.body}
        >
            <Input
                className="cursorPointer"
                onClick={() => setOpen(true)}
                readOnly
                suffix={(loading)
                    ? <LoadingOutlined style={{ color: 'gray' }} />
                    : <ImportOutlined onClick={() => setOpen(!open)} />}
                value={selected?.title}
                disabled={disabled}
            />
        </Popover>
    );
};

LookupInjuryLocationField.propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default LookupInjuryLocationField;
