import './Form.scss';
import PropTypes from 'prop-types';
import { Form as FormBase } from 'antd';
import scrollIntoView from 'scroll-into-view-if-needed';

const scrollToField = (fieldName) => {
    const className = `form-item-${Array.isArray(fieldName) ? fieldName.join('-') : fieldName}`;
    const el = document.querySelector(`.${className}`);
    if (el) {
        scrollIntoView(el, { block: 'center' });
        return true;
    }
    return false;
};
const onFinishFailed = ({ errorFields }) => {
    if (errorFields) {
        const [field] = errorFields;
        scrollToField(field.name);
    }
};

const Form = ({ ...rest }) => (
    <FormBase
        scrollToFirstError={{ focus: true }}
        autoComplete="off"
        {...rest}
    />
);

Form.useFormInstance = FormBase.useFormInstance;
Form.useForm = FormBase.useForm;
Form.Item = FormBase.Item;
Form.List = FormBase.List;
Form.Provider = FormBase.Provider;
Form.onFinishFailed = onFinishFailed;
Form.useWatch = FormBase.useWatch;

const FormItem = ({ name, className, ...rest }) => (
    <Form.Item
        name={name}
        className={className + (name ? ` form-item-${name}` : '')}
        {...rest}
    />
);

FormItem.propTypes = {
    name: PropTypes.string,
    className: PropTypes.string,
};

export { Form, FormItem };
