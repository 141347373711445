Array.prototype.unique = function unique() {
    const ret = [];
    const used = new Set();
    for (let i = 0; i < this.length; i++) {
        if (!used.has(this[i])) {
            ret.push(this[i]);
            used.add(this[i]);
        }
    }
    return ret.filter(i => i !== null);
};

Array.prototype.sortByString = function sortByPros(prop, desc) {
    this.sort((a, b) => (desc ? -1 : 1) * ((a[prop] || '').toLowerCase() < (b[prop] || '').toLowerCase() ? -1 : 1));
    return this;
};

Array.prototype.sortByDate = function sortByPros(prop, desc) {
    this.sort((a, b) => (desc ? -1 : 1) * ((a[prop] || '').toLowerCase() < (b[prop] || '').toLowerCase() ? -1 : 1));
    return this;
};

Array.prototype.sortByNumber = function sortByPros(prop, desc) {
    this.sort((a, b) => (desc ? -1 : 1) * ((a[prop] || '') < (b[prop] || '') ? -1 : 1));
    return this;
};

Array.prototype.sum = function sum(prop) {
    return this.map(item => item[prop]).reduce((a, b) => a + b, 0);
};

Array.create = function create(n, start = 0) {
    return Array.from(Array(n).keys().map(i => i + start));
}; 

Array.fromTo = function createFromTo(from, to) {
    return Array.from(Array(to - from).keys()).map(i => i + from);
};

Array.isEmpty = function isEmpty(n) {
    return Array.isArray(n) && n.length === 0;
}; 

String.prototype.substringAfter = function substringAfter(x) {
    const idx = this.toLowerCase().indexOf(x.toLowerCase());
    return idx < 0 ? this.toString() : this.substring(idx + x.length);
};

Array.prototype.commatize = function commatize() {
    return this.filter(Boolean).join(', ');
};
