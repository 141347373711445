import { Outlet } from 'react-router-dom';
import React from 'react';
import { useSelector } from 'react-redux';
import { Header } from './Header';

const Root = () => {
    const me = useSelector(state => state.me);
    return (
        <>
            <Header />
            <Outlet context={{ me }} />
        </>
    );
};

export default Root;
