import React from 'react';
import PropTypes from 'prop-types';
import { Empty } from '../Empty';
import { Spin } from './default';

const SpinEmpty = ({ spinning = false, items = [1], children, className = '' }) => {
    const noItemsFound = items && items.length === 0;
    return (
        <Spin
            className={`spin-empty ${className}`}
            spinning={!!spinning}
        >
            {!spinning && noItemsFound && (
                <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    className="empty-in-spin"
                />
            )}
            {!spinning && !noItemsFound && children}
        </Spin>
    );
};

SpinEmpty.propTypes = {
    spinning: PropTypes.bool,
    items: PropTypes.array,
    children: PropTypes.any.isRequired,
    className: PropTypes.string
};

export { SpinEmpty };
