import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Tooltip } from 'library';

const InputSsn = ({
    value,
    onChange,
    required = false,
    disabled = false,
    last4Only = false,
    ...rest
}) => {
    const [thisValue, setThisValue] = useState();

    useEffect(() => {
        let val = (value || '').replace(/\D/g, '');
        if (last4Only && val.length > 4) {
            val = val.substr(val.length - 4);
        } else if (val.length >= 9) {
            val = val.replace(/^(.{3})(.{2})(.*)$/, '$1-$2-$3');
        }
        setThisValue(val);
    }, [value, last4Only]);

    const onBeforeChange = (e) => {
        const val = e.target.value || '';
        onChange(val.replace(/\D/g, ''));
    };

    if (last4Only) {
        const open = !disabled && last4Only && (value || '').toString().length < 4;

        return (
            <Tooltip
                title="Please enter last 4 digits only"
                open={open}
                placement="right"
                align={{
                    offset: required ? [100, 0] : [],
                }}
            >
                <Input
                    allowClear
                    maxLength={4}
                    value={thisValue}
                    onChange={onBeforeChange}
                    disabled={disabled}
                    {...rest}
                />
            </Tooltip>
        );
    }

    return (
        <Input
            allowClear
            maxLength={11}
            value={thisValue}
            onChange={onBeforeChange}
            disabled={disabled}
            {...rest}
        />
    );
};

InputSsn.propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    last4Only: PropTypes.bool
};

export { InputSsn };
