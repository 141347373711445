import React from 'react';
import { formats } from 'utils';
import { DatePicker } from 'library';

const { RangePicker } = DatePicker;

const InputDateRange = props => (
    <RangePicker
        format={formats.date.in}
        {...props}
    />
);

export { InputDateRange };
