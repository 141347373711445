import React from 'react';
import { Help } from 'library';
import PropTypes from 'prop-types';
import { DEFAULT_OFF_MODE_MESSAGE, DISTRIBUTION_MODE } from 'config/location';

const LocationTitle = ({ distributionMode, offModeMessage, title }) => {
    if (distributionMode !== DISTRIBUTION_MODE.LIVE) {
        return (
            <Help tooltip={offModeMessage || DEFAULT_OFF_MODE_MESSAGE}>
                <strike>{title}</strike>
            </Help>
        );
    }
    return title;
};

LocationTitle.propTypes = {
    offModeMessage: PropTypes.object,
    title: PropTypes.string.isRequired,
    distributionMode: PropTypes.number.isRequired
};

export default LocationTitle;
