import { QUESTION_TYPE_MAP, RULES } from 'config';

export const transformOptions = (item) => {
    if (!Array.isArray(item.options)) {
        return undefined;
    }
    return item.options.map(op => ({
        value: Object.isDefined(op.id) ? op.id : op.value,
        label: (Object.isDefined(op.text) ? op.text : op.title) || '',
        code: op.code || ''
    }));
};

const transformList = (id, { data: { data } }, allCategories) => {
    const getQuestions = categoryId => data
        .filter(item => item.categoryId === categoryId)
        .map(item => ({
            id: item.name,
            name: item.name,
            label: item.customTitle || item.title || '',
            required: !!parseInt(item.required, 10),
            disabled: !!parseInt(item.readonly, 10),
            estimated: !!parseInt(item.estimated, 10),
            maxLength: item.maxLength,
            categoryId: item.categoryId,
            options: transformOptions(item),
            type: (QUESTION_TYPE_MAP[item.type] || 'N/A'),
            showIf: item.showIf,
            copy: item.dataRelation?.copy,
            fetch: item.fetch,
            rules: RULES[item.name]
        }));
    const categories = data
        .map(item => item.categoryId)
        .unique()
        .map((cid) => {
            const category = allCategories.find(it => it.id === cid);
            if (!category) {
                return undefined;
            }
            return {
                ...category,
                fields: getQuestions(category.id)
            };
        }).filter(item => !!item)
        .sort((a, b) => (a.ord > b.ord ? 1 : -1));

    return categories;
};

export default transformList;
