/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const incidentsSlice = createSlice({
    name: 'incidents',
    initialState,
    reducers: {
        setOpen: (state, action) => {
            state.open = action.payload;
        },
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
        clearFilters: () => initialState,
    },
});

const { setOpen, setFilters, clearFilters, initFilters } = incidentsSlice.actions;
export { setOpen, setFilters, clearFilters, initFilters };

export default incidentsSlice.reducer;
