import { configureStore, createListenerMiddleware } from '@reduxjs/toolkit';

import call, { clearCall } from './call';
import me, { signIn, signOut, signOutEs, getEnterpriseMe, getGenesysMe } from './me';
import location, { clearLocation } from './location';
import incident, { clearIncident } from './incident';
import incidents from './incidents';

const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
    actionCreator: signIn.fulfilled,
    effect: async (action, listenerApi) => {
        listenerApi.dispatch(getEnterpriseMe());
        listenerApi.dispatch(getGenesysMe());
    },
});

listenerMiddleware.startListening({
    actionCreator: signOut.fulfilled,
    effect: async (action, listenerApi) => {
        listenerApi.dispatch(signOutEs());
    }
});

listenerMiddleware.startListening({
    actionCreator: clearIncident,
    effect: async ({ payload: clearCallToo }, listenerApi) => {
        listenerApi.dispatch(clearLocation());
        if (clearCallToo) {
            listenerApi.dispatch(clearCall());
        }
    },
});

export const store = configureStore({
    reducer: {
        call,
        me,
        location,
        incident,
        incidents
    },
    middleware: getDefault => getDefault()
        .prepend(listenerMiddleware.middleware),
});
