import './UserAvatar.scss';
import PropTypes from 'prop-types';
import { Avatar, Tooltip } from 'library';
import { AvatarRN, AvatarIC } from 'assets';

const UserAvatar = ({ size = 32, isRN = false, title, className, ...rest }) => {
    const avatar = isRN ? AvatarRN : AvatarIC;
    return (
        <Tooltip title={title}>
            <Avatar
                className={`avatar ${className}`}
                src={avatar}
                size={size}
                {...rest}
            />
        </Tooltip>
    );
};

UserAvatar.propTypes = {
    size: PropTypes.number,
    isRN: PropTypes.bool,
    title: PropTypes.any,
    className: PropTypes.string
};

export { UserAvatar };
