import { transformTableParams, randomTo } from 'utils';
import api from '../api';
import transformList from './transformer.list';
import transformUpdate from './transformer.update';
import transformCreate from './transformer.create';
import transformGet from './transformer.get';

class EsIncidentsService {
    static async get(incidentId) {
        return api.get(`/api/v2/incidents/${incidentId}`)
            .then(({ data }) => data)
            .then(transformGet);
    }

    static list(args) {
        const params = transformTableParams(args, true);
        return api.get('/api/v2/incidents', params)
            .then(transformList);
    }

    static search(filters) {
        const params = transformTableParams(filters, true);
        return api.post('/api/v2/incidents/searches', params)
            .then(transformList);
    }

    static find(filters) {
        return this.list({
            page: 1,
            perPage: 1,
            filters
        })
            .then((result) => {
                if (result?.data?.length) {
                    return result.data[0];
                }
                return undefined;
            });
    }

    static create(values) {
        return api.post('/api/v2/incidents', transformCreate(values))
            .then(({ data }) => data)
            .then(transformGet);
    }

    static update(id, values) {
        const postData = transformUpdate(values);
        return api.put(`/api/v2/incidents/${id}`, postData)
            .then(({ data }) => data)
            .then(transformGet);
    }

    static patch(id, values) {
        return api.put(`/api/v2/incidents/${id}`, values)
            .then(({ data }) => data)
            .then(transformGet);
    }

    static clone(id, values) {
        return api.post(`/api/v2/incidents/${id}`, values)
            .then(({ data }) => data)
            .then(transformGet);
    }

    static void(id) {
        return api.delete(`/api/v2/incidents/${id}`)
            .then(({ data }) => data);
    }

    static async calls(incidentId) {
        return api.get(`/api/v2/incidents/${incidentId}/calls`)
            .then(data => data.data)
            .then(data => data.data);
    }

    static async severities(incidentId) {
        return api.get(`/api/v2/incidents/${incidentId}/severities`)
            .then(data => data.data?.data)
            .then(data => data.map(item => ({ ...item, id: randomTo(1000000) })));
    }
}

export { EsIncidentsService };
