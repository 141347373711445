import './LookupTopLocation.scss';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Form, Popover } from 'library';
import { useSelector } from 'react-redux';
import { ApartmentOutlined, LoadingOutlined } from '@ant-design/icons';
import PopupTree from './popup';

const LookupTopLocation = ({ topLocation = {} }) => {
    const [open, setOpen] = useState(false);
    const call = useSelector(state => state.call);
  
    if (!call.toPhone) {
        return null;
    }
    return (
        <Form.Item
            label="Call-Identified Location"
        >
            <Popover
                content={(
                    <PopupTree
                        root={topLocation}
                        setOpen={setOpen}
                    />
                )}
                placement="right"
                open={open}
            >
                <Input
                    value={topLocation?.title}
                    readOnly
                    disabled={topLocation.$loading}
                    className="cursorPointer"
                    onClick={() => setOpen(!open)}
                    suffix={(topLocation.$loading)
                        ? <LoadingOutlined />
                        : (
                            <ApartmentOutlined
                                onClick={() => setOpen(!open)}
                                title={`${topLocation.title} Location Tree`}
                            />
                        )}
                />
            </Popover>
        </Form.Item>
    );
};

LookupTopLocation.propTypes = {
    topLocation: PropTypes.object
};

export { LookupTopLocation };
